var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-user"},[_c('validation-observer',{ref:"addUserForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.first_name'),"label-for":"Name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"first name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.first_name),callback:function ($$v) {_vm.$set(_vm.form_data, "first_name", $$v)},expression:"form_data.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.last_name'),"label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.last_name),callback:function ($$v) {_vm.$set(_vm.form_data, "last_name", $$v)},expression:"form_data.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"rules":"required|min:9|max:11","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.phone),callback:function ($$v) {_vm.$set(_vm.form_data, "phone", $$v)},expression:"form_data.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.email'),"label-for":"SKU"}},[_c('validation-provider',{attrs:{"rules":"required","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"password","label":_vm.$t('g.password')}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":!_vm.isEdit ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"············"},model:{value:(_vm.form_data.password),callback:function ($$v) {_vm.$set(_vm.form_data, "password", $$v)},expression:"form_data.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"password_confirmation","label":_vm.$t('g.password_confirmation')}},[_c('validation-provider',{attrs:{"name":"Password confirmation","vid":"password confirmation","rules":!_vm.isEdit ? 'required|confirmed:password' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirmation","type":_vm.passwordConfirmationFieldType,"state":errors.length > 0 ? false : null,"name":"password confirmation","placeholder":"············"},model:{value:(_vm.form_data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form_data, "password_confirmation", $$v)},expression:"form_data.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmationToggleIcon},on:{"click":_vm.togglePasswordConfirmationVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.birthDate'),"label-for":"birthDate"}},[_c('validation-provider',{attrs:{"name":"Birth Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"id":"example-datepicker"},model:{value:(_vm.form_data.birthdate),callback:function ($$v) {_vm.$set(_vm.form_data, "birthdate", $$v)},expression:"form_data.birthdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.nationality')}},[_c('validation-provider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.countries,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.nationality_id),callback:function ($$v) {_vm.$set(_vm.form_data, "nationality_id", $$v)},expression:"form_data.nationality_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.role')}},[_c('validation-provider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.roles,"text-field":"name","value-field":"id"},model:{value:(_vm.form_data.role_id),callback:function ($$v) {_vm.$set(_vm.form_data, "role_id", $$v)},expression:"form_data.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.id_number'),"label-for":"Id number"}},[_c('validation-provider',{attrs:{"name":"Id number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.id_number),callback:function ($$v) {_vm.$set(_vm.form_data, "id_number", $$v)},expression:"form_data.id_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.id_expiry_date')}},[_c('validation-provider',{attrs:{"name":"ID expiry date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.id_expiry_date),callback:function ($$v) {_vm.$set(_vm.form_data, "id_expiry_date", $$v)},expression:"form_data.id_expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('users.id_number_image')}},[_c('validation-provider',{attrs:{"name":"ID Number image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":_vm.$t('users.choose_a_file_or_drop_it_here'),"drop-placeholder":_vm.$t('users.drop_file_here')},model:{value:(_vm.form_data.id_number_image),callback:function ($$v) {_vm.$set(_vm.form_data, "id_number_image", $$v)},expression:"form_data.id_number_image"}}),_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 110, height: 110 }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),(_vm.idUrl)?_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"md":"6"}},[_c('div',{staticClass:"image-input-area"},[_c('div',{staticClass:"image-icon",staticStyle:{"overflow":"hidden"},style:({
                  maxWidth: _vm.form_data.id_number_image ? '100%' : '',
                  maxHeight: _vm.form_data.id_number_image ? '100%' : '',
                })},[_c('img',{staticClass:"id-image",attrs:{"src":_vm.idUrl,"alt":"Cover"}})])])]):_vm._e()],1)],1),_c('div',{staticClass:"d-flex flex-wrap justify-content-end",staticStyle:{"text-align":"end","gap":"20px"}},[_c('b-button',{staticClass:"align-self-end",attrs:{"variant":"outline-danger"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("g.cancel"))+" ")]),_c('b-button',{staticClass:"align-self-end",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validation()}}},[(!_vm.isEdit)?_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"16"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("edit_user") : _vm.$t("g.users/user_list/add_user"))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }