<template>
  <!-- <loading v-if="isLoading" />
  <div v-else class="add-user"> -->
  <div class="add-user">
    <validation-observer ref="addUserForm">
      <b-form @submit.prevent>
        <b-card>
          <b-row>
            <b-col class="mb-2" cols="12" md="4">
              <!-- Start Input -->
              <b-form-group :label="$t('users.first_name')" label-for="Name">
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="first name"
                >
                  <b-form-input
                    v-model="form_data.first_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" md="4">
              <!-- Start Input -->
              <b-form-group
                :label="$t('users.last_name')"
                label-for="last_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="last name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.last_name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Emd Input -->
            </b-col>

            <b-col class="mb-2" cols="12" md="4">
              <!-- Start Input -->
              <b-form-group :label="$t('users.phone')" label-for="phone">
                <validation-provider
                  rules="required|min:9|max:11"
                  #default="{ errors }"
                  name="phone"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.phone"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col class="mb-2" cols="12" md="4">
              <!-- Start Input -->
              <b-form-group :label="$t('users.email')" label-for="SKU">
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="email"
                >
                  <b-form-input
                    type="email"
                    v-model="form_data.email"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col md="4" class="px-xl-2 mx-auto">
              <b-form-group label-for="password" :label="$t('g.password')">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  :rules="!isEdit ? 'required' : null"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="form_data.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" class="px-xl-2 mx-auto">
              <b-form-group
                label-for="password_confirmation"
                :label="$t('g.password_confirmation')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password confirmation"
                  vid="password confirmation"
                  :rules="!isEdit ? 'required|confirmed:password' : null"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password_confirmation"
                      v-model="form_data.password_confirmation"
                      class="form-control-merge"
                      :type="passwordConfirmationFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password confirmation"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordConfirmationToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordConfirmationVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mb-2" cols="12" md="4">
              <!-- Start Input -->
              <b-form-group
                :label="$t('users.birthDate')"
                label-for="birthDate"
              >
                <validation-provider #default="{ errors }" name="Birth Date">
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="form_data.birthdate"
                    class="mb-2"
                  ></b-form-datepicker>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('users.nationality')">
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.nationality_id"
                    :options="countries"
                    text-field="name"
                    value-field="id"
                  ></b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('users.role')">
                <validation-provider
                  #default="{ errors }"
                  name="roles"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.role_id"
                    :options="roles"
                    text-field="name"
                    value-field="id"
                  ></b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mb-2" cols="12" md="4">
              <!-- Start Input -->
              <b-form-group
                :label="$t('users.id_number')"
                label-for="Id number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Id number"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.id_number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- End Input -->
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('users.id_expiry_date')">
                <validation-provider
                  #default="{ errors }"
                  name="ID expiry date"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.id_expiry_date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group :label="$t('users.id_number_image')">
                <validation-provider
                  #default="{ errors }"
                  name="ID Number image"
                >
                  <b-form-file
                    v-model="form_data.id_number_image"
                    :placeholder="$t('users.choose_a_file_or_drop_it_here')"
                    :drop-placeholder="$t('users.drop_file_here')"
                  ></b-form-file>
                  <RecommendationUploadImage
                    :dimensions="{ width: 110, height: 110 }"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" class="px-xl-2 mx-auto" v-if="idUrl">
              <div class="image-input-area">
                <div
                  class="image-icon"
                  style="overflow: hidden"
                  :style="{
                    maxWidth: form_data.id_number_image ? '100%' : '',
                    maxHeight: form_data.id_number_image ? '100%' : '',
                  }"
                >
                  <img :src="idUrl" class="id-image" alt="Cover" />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <div
          style="text-align: end; gap: 20px"
          class="d-flex flex-wrap justify-content-end"
        >
          <b-button
            variant="outline-danger"
            class="align-self-end"
            @click="cancel"
          >
            {{ $t("g.cancel") }}
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            class="align-self-end"
            @click.prevent="validation()"
          >
            <feather-icon
              v-if="!isEdit"
              icon="PlusIcon"
              class="text-white"
              size="16"
            />
            {{ isEdit ? $t("edit_user") : $t("g.users/user_list/add_user") }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import loading from "@/components/shared/loading/loading.vue";
import { required, confirmed, min, max } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  togglePasswordVisibility,
  togglePasswordConfirmationVisibility,
} from "@core/mixins/ui/forms";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormFile,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
export default {
  name: "AddUser",
  components: {
    loading,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    BForm,
    BFormSelect,
    BFormFile,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    RecommendationUploadImage,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmationVisibility],
  data() {
    return {
      isLoading: true,
      countries: null,
      isEdit: false,
      roles: null,
      idUrl: null,
      old_phone: null,
      old_email: null,
      form_data: {
        first_name: null, //required
        last_name: null,
        phone: null, //required
        email: null, //required
        nationality_id: null, //required
        birthdate: null,
        id_number: null, //required
        id_expiry_date: null,
        id_number_image: [],
        role_id: null, //required
        password: null, //required
        password_confirmation: null, //required
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getUserData();
      this.isEdit = true;
    }
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const [coutries, roles] = await Promise.all([
          this.$http.get("web/countries"),
          this.$http.get("vendor/roles?guard=vendor"),
        ]);

        this.countries = coutries.data.data;
        this.roles = roles.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    async getUserData() {
      try {
        this.isLoading = true;
        const res = await this.$http.get(
          `vendor/my-vendor/${this.$route.params.id}`
        );
        if (res.status === 200 || res.status === 201) {
          for (const item in this.form_data) {
            if (item === "nationality_id") {
              this.form_data[item] = res.data.data.nationality.id;
            } else if (item === "role_id") {
              this.form_data[item] = res.data.data.roles[0]?.id;
            } else if (item === "phone") {
              this.form_data.phone = this.old_phone =
                res.data.data.phone.length > 0
                  ? res.data.data.phone[0].phone
                  : null;
            } else if (item === "email") {
              this.form_data.email = this.old_email = res.data.data.email;
            } else {
              this.form_data[item] = res.data.data[item];
            }
          }
          this.form_data.password = this.form_data.password_confirmation = null;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    cancel() {
      for (let item in this.form_data) {
        this.form_data[item] = null;
      }
      this.$router.push({ name: "users" });
    },

    async handleSubmitUser() {
      try {
        this.isLoading = true;
        const formData = this.collectData();
        let res = null;
        if (this.isEdit) {
          res = await this.$http.post(
            `vendor/update/${this.$route.params.id}?_method=put`,
            formData
          );
        } else {
          res = await this.$http.post("vendor/store", formData);
        }
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast("success", res.data.message);
          this.$router.push({ name: "users" });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    validation() {
      this.$refs.addUserForm.validate().then((success) => {
        if (success) {
          this.handleSubmitUser();
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
    collectData() {
      const formData = new FormData();
      for (let item in this.form_data) {
        if (
          this.form_data[item] !== null &&
          this.form_data[item] !== "" &&
          this.form_data[item] !== undefined
        ) {
          if (item === "phone") {
            this.form_data.phone !== this.old_phone
              ? formData.append(item, this.form_data[item])
              : null;
          } else if (item === "email") {
            this.form_data.email !== this.old_email
              ? formData.append(item, this.form_data[item])
              : null;
          } else if (item === "role_id") {
            formData.append("role_id[0]", this.form_data[item]);
          } else {
            formData.append(item, this.form_data[item]);
          }
        }
      }
      return formData;
    },
  },
  watch: {
    "form_data.id_number_image": function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.idUrl = URL.createObjectURL(val);
      }
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
